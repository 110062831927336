<template>
  <div>
    <CRow v-if="userData.user_address">
      <CCol v-if="userData.feature_active_school == 1">
        <div class="scrollmenu">
          <Transition name="bounce">
            <a href="./createTeachingLog/76" v-if="show">
              <div><img :src="$hostUrl + '/flagLogo.png'" width="50px" />
              </div>
              <div class="text-black text70">เช็คชื่อหน้าเสาธง</div>
            </a>
          </Transition>
          <Transition name="bounce">
            <a href="./teachingLog" v-if="show">
              <div><img :src="$hostUrl + '/teachingLogLogo.png'" width="50px" />
              </div>
              <div class="text-black text70">บันทึกการสอน</div>
            </a>
          </Transition>



        </div>
      </CCol>
      <CCol class="alert alert-danger" v-else>-----สัญญาหมดอายุ-----</CCol>
    </CRow>
    <!--ALL ACCESS-->
    <WidgetFlag />

    <!--OFFECER AREA-->

    <WidgetsDropdown v-if="userData.user_address" />
    <CRow>
    
      <CardScoringForm v-if="userData.user_level && userData.feature_active_school == 1" />
      <CCol class="alert alert-danger" v-else>-----สัญญาหมดอายุ-----</CCol>

    </CRow>
    <!--STUDENT AREA-->
    <CRow>
      <CCol lg="12" class="text-right">
        <Button class="btn btn-black" @click="$router.push({ name: 'Profile' })"
          v-if="userData.std_ordinal">อัพเดทข้อมูลส่วนบุคคล</Button>
      </CCol>
    </CRow>
    <TblMyScore v-if="userData.std_ordinal" />
    <TblMyEnrollment v-if="userData.std_ordinal" />
    <TblMyDeposit v-if="userData.std_ordinal" />



    <!--ALL ACCESS-->

    <CRow>
      <CCol lg="12">
        <TblCovidByDate />
      </CCol>
    </CRow>
  </div>
</template>

<script>
import MainChartExample from "./charts/MainChartExample";
import WidgetsDropdown from "./widgets/WidgetsDropdown";
import WidgetsBrand from "./widgets/WidgetsBrand";
import Widgets from "./widgets/Widgets";
import CardScoringForm from "./components/CardScoringForm";


import WidgetWorkFromHome from "./widgets/WidgetWorkFromHome";
import WidgetFlag from "./widgets/WidgetFlag.vue";
import WidgetStd from "./widgets/WidgetStd.vue";
import TblMyScore from "./components/TblMyScore";

import TblCovidByDate from "./components/TblCovidByDate";
import TblMyEnrollment from "./components/TblMyEnrollment";
import TblMyDeposit from "./components/TblMyDeposit";



export default {
  name: "Dashboard",
  components: {
    MainChartExample,
    WidgetsDropdown,
    WidgetsBrand,
    CardScoringForm,

    Widgets,
    WidgetWorkFromHome,
    WidgetFlag,
    WidgetStd,
    TblMyScore,
    TblCovidByDate,
    TblMyEnrollment,
    TblMyDeposit

  },
  data() {
    return {
      selected: "Month",
      userData: this.$cookies.get("user"),
      show: false
    };
  },
  methods: {
    openLink(targetUrl) {
      window.open(targetUrl);
    },
  },
  mounted() {
    //console.log(this.userData);
    this.show = true;
  },
};
</script>

<style scoped>
div.scrollmenu {
  overflow: auto;
  white-space: nowrap;
}

div.scrollmenu a {
  display: inline-block;
  color: white;
  text-align: center;
  padding: 14px;
  text-decoration: none;
}

div.scrollmenu a:hover {
  background-color: white;
}

.bounce-enter-active {
  animation: bounce-in 1.0s;
}

.bounce-leave-active {
  animation: bounce-in 0.5s reverse;
}

@keyframes bounce-in {
  0% {
    transform: scale(0);
  }

  50% {
    transform: scale(1.25);
  }

  100% {
    transform: scale(1);
  }
}
</style>
