<template>
  <div>
    <CRow>
      <CCol md="4" sm="6">
        <CCard>
          <CCardHeader  color="linkedin">
            <CRow class="text-center text-white">
              <CCol> <h3 style="height: 52px; margin-top: 25px">บันทึกการสอน</h3></CCol>
                

            </CRow>
          </CCardHeader>
          <CCardBody>
            <CRow>
              <CCol
                ><a class="btn btn-warning btn-block" href="./#/">
                  แผนการสอน
                </a></CCol
              >
              <CCol>
                <a
                  class="btn btn-success btn-block text-white"
                  href="./teachingLog/"
                >
                  บันทึกการสอน
                </a></CCol
              >
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import WidgetsBrand from "./WidgetsBrand";
import WidgetsDropdown from "./WidgetsDropdown";
import { CChartLineSimple, CChartBarSimple } from "../charts/index.js";

export default {
  name: "Widgets",
  components: {
    CChartLineSimple,
    CChartBarSimple,
    WidgetsBrand,
    WidgetsDropdown,
  },
};
</script>
